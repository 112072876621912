import {
  SettingField,
  isSettingField,
  Designer,
  TransformStage,
  LiveEditing,
  isDragNodeDataObject,
  isDragNodeObject,
  isSimulatorHost,
  DragObjectType,
  isNode,
  DesignerView,
} from '@alilc/lowcode-designer';
import DesignerPlugin from '@alilc/lowcode-plugin-designer';
import { Editor } from '@alilc/lowcode-editor-core';
import { Dragon, simulatorRendererSymbol } from '@alilc/lowcode-shell';

export default function getDesignerCabin(editor: Editor) {
  const designer = editor.get('designer') as Designer;

  return {
    SettingField,
    isSettingField,
    dragon: Dragon.create(designer.dragon),
    TransformStage,
    LiveEditing,
    DragObjectType,
    isDragNodeDataObject,
    isDragNodeObject,
    DesignerView,
    DesignerPlugin,
    isNode,
    isSimulatorHost,
    simulatorRendererSymbol,
  };
}