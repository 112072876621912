export {
  Title,
  Tip,
  shallowIntl,
  createIntl,
  intl,
  createSetterContent,
  obx,
  untracked,
  computed,
  observer,
  globalLocale,
  makeObservable,
} from '@alilc/lowcode-editor-core';